import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import NotFoundIcon from '../assets/svg/notFound.inline.svg'

const NotFoundPage = data => {
    return (
        <Layout>
            <Seo title='404: Not Found' meta={{ metaDesc: 'Page not found' }} lang='en-US' />
            <div className='global-container' style={{ textAlign: 'center' }}>
                <h1>Page Not Found</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                <NotFoundIcon />
            </div>
        </Layout>
    )
}

export default NotFoundPage
